import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar/Navbar'
import Hero from '../../components/hero/hero';
import Features from '../../components/Features/Features';
import About from '../../components/AboutHome/aboutHome';
// import CampaignSection from '../../components/CampaignSection/CampaignSection';
// import MissionVission from '../../components/MissionVision/MissionVission';
// import EventSection from '../../components/EventSection/EventSection';
import BlogSection from '../../components/BlogSection/BlogSection';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';

const HomePage =() => {
    return(
        <Fragment>
            <Navbar hclass={'wpo-header-style-4'} home={true}/>
            <Hero/>
            <Features/>
            <About/>
            
            <BlogSection/>
            <Footer/> 
            <Scrollbar/>

            {/* <CampaignSection/> */}
            {/* <EventSection/> */}

        </Fragment>
    )
};
export default HomePage;