import React from 'react'
import { Link } from 'react-router-dom'
import MobileMenu from '../MobileMenu/MobileMenu'
import LogoWhite from '../../images/logo/logo-s3.svg'
import LogoBlack from '../../images/logo/logo-black-and-white.svg'


const Header = (props) => {

    console.log('Current hclass:', props.hclass);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (

        <header id="header" className={`${props.hclass}`}>
            {/* <HeaderTopbar /> */}
            <div className="wpo-site-header">
                <nav className="navigation navbar navbar-expand-lg navbar-light">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-lg-3 col-md-3 col-sm-3  col-3 d-lg-none dl-block">
                                <div className="mobail-menu">
                                    <MobileMenu />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-5 col-sm-4 col-6">
                                <div className="navbar-header">
                                    <Link onClick={ClickHandler} className="navbar-brand" to="/"><img src={props.home ? LogoWhite : LogoBlack} alt="logo" /></Link>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-1 col-sm-1 col-1">
                                <div id="navbar" className="collapse navbar-collapse navigation-holder">
                                    <button className="menu-close"><i className="ti-close"></i></button>
                                    <ul className="nav navbar-nav mb-2 mb-lg-0">
                                        <li className="menu-item-has-children">
                                            <Link onClick={ClickHandler} to="/">Home</Link>
                                        </li>
                                        <li><Link onClick={ClickHandler} to="/about">About</Link></li>
                                        <li><Link onClick={ClickHandler} to="/stories">Stories</Link></li>
                                        <li><Link onClick={ClickHandler} to="/papers">Papers</Link></li>
                                        <li className="menu-item-has-children">
                                            <Link onClick={ClickHandler} to="/fellowship">Fellowship</Link>
                                        </li>
                                        <li><Link onClick={ClickHandler} to="/partner">Partner</Link></li>
                                    </ul>
                                </div>
                            </div>
                        
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    )
}

export default Header;