import React, { useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';

const ContactForm = () => {
    const [forms, setForms] = useState({
        name: '',
        email: '',
        university: '',
        fellow: '',
        why: ''
    });
    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));
    const [isSubmitted, setIsSubmitted] = useState(false);  // Track if the form is submitted

    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value });
        validator.showMessages();
    };

    const submitHandler = e => {
        e.preventDefault();
        if (validator.allValid()) {
            const formData = new URLSearchParams({
                'entry.392246479': forms.name,
                'entry.968792634': forms.email,
                'entry.1960581635': forms.university,
                'entry.500883110': forms.fellow,
                'entry.1508480358': forms.why,
            });

            const formURL = 'https://docs.google.com/forms/u/0/d/e/1FAIpQLSdXJoPFGj_fBQu_UVcfdckrXy794HHhOeyJz66q9mrp9ZLc3w/formResponse';
            fetch(formURL, {
                method: 'POST',
                mode: 'no-cors', // Needed to prevent CORS errors
                body: formData,
            }).then(response => {
                console.log('Form submitted', response);
                setIsSubmitted(true);  // Set isSubmitted to true on successful submission
                validator.hideMessages();
            }).catch(error => {
                console.error('Error submitting form', error);
            });
        } else {
            validator.showMessages();
        }
    };

    return (
        <div>
            {isSubmitted ? (
                <div className="submission-message text-center h4">
                    Thank you for your submission. We will get back to you soon!
                </div>
            ) : (
                <form onSubmit={submitHandler} className="contact-validation-active">
                    <div className="row">
                        <div className="col col-lg-6 col-12">
                            <div className="form-field">
                                <input
                                    value={forms.name}
                                    type="text"
                                    name="name"
                                    onBlur={changeHandler}
                                    onChange={changeHandler}
                                    placeholder="Your Name" />
                                {validator.message('name', forms.name, 'required|alpha_space')}
                            </div>
                        </div>
                        <div className="col col-lg-6 col-12">
                            <div className="form-field">
                                <input
                                    value={forms.email}
                                    type="email"
                                    name="email"
                                    onBlur={changeHandler}
                                    onChange={changeHandler}
                                    placeholder="Your Email" />
                                {validator.message('email', forms.email, 'required|email')}
                            </div>
                        </div>
                        <div className="col col-lg-6 col-12">
                            <div className="form-field">
                                <input
                                    value={forms.university}
                                    type="text"
                                    name="university"
                                    onBlur={changeHandler}
                                    onChange={changeHandler}
                                    placeholder="Your Affiliation" />
                                {validator.message('affiliation', forms.university, 'required|alpha_space')}
                            </div>
                        </div>
                        <div className="col col-lg-6 col-12">
                            <div className="form-field">
                                <select
                                    onBlur={changeHandler}
                                    onChange={changeHandler}
                                    value={forms.fellow}
                                    name="fellow">
                                    <option value="">Select Fellowship Type</option>
                                    <option value="Policy Fellow">Policy Fellow</option>
                                    <option value="Research Fellow">Research Fellow</option>
                                </select>
                                {validator.message('fellow', forms.fellow, 'required')}
                            </div>
                        </div>
                        <div className="col col-lg-12 col-12">
                            <textarea
                                onBlur={changeHandler}
                                onChange={changeHandler}
                                value={forms.why}
                                name="why"
                                placeholder="If you're interested in becoming an Amplify Fellow: Why do you want to join our fellowship program? Tell us briefly about your previous work and what you're hoping to work on with us.">
                            </textarea>
                            {validator.message('why', forms.why, 'required')}
                        </div>
                    </div>
                    <div className="submit-area">
                        <button type="submit" className="theme-btn">Submit Now</button>
                    </div>
                </form>
            )}
        </div>
    );
};

export default ContactForm;
