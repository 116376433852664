
import React, { useState } from 'react'
import ModalVideo from 'react-modal-video'
import '../../../node_modules/react-modal-video/scss/modal-video.scss';



const VideoModal = (props) => {
  
  const [isOpen, setOpen] = useState(false)

  return (
    <React.Fragment>
      <ModalVideo channel='vimeo' autoplay isOpen={isOpen} videoId={props.src} onClose={() => setOpen(false)} />
      {/* <GoogleDriveVideoModal videoUrl="https://drive.google.com/file/d/1eHleYeNK1Si3GBNv1IvjqRnmTFHqiXnR/preview" /> */}

      <div className="video-btn">
        <ul>
          <li>
            <button className="btn-wrap" onClick={() => setOpen(true)}><i className="fi flaticon-play" aria-hidden="true"></i></button>
          </li>
        </ul>
      </div>

    </React.Fragment>
  )
}

export default VideoModal;