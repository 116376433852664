import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar/Navbar.js';
import PageTitle from '../../components/pagetitle/PageTitle.js'
import Scrollbar from '../../components/scrollbar/scrollbar.js'
import { useParams } from 'react-router-dom'
import papers from '../../api/papers.js'
import PaperSingle from '../../components/PaperComponents/PaperSingle/PaperSingle.js'
import Footer from '../../components/footer/Footer.js';



const BlogDetails =() => {

    const { slug } = useParams()

    const BlogDetails = papers.find(item => item.slug === slug)

    return(
        <Fragment>
            <Navbar hclass={'wpo-header-style-5'}/>
            <PageTitle pageTitle={BlogDetails.title} pagesub={'Papers'} pageDescription={BlogDetails.description}/> 
             <PaperSingle/>
             <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default BlogDetails;
