import React from 'react'

import abImg from '../../images/about.jpg'


const About = (props) => {
    return (
        <section className="wpo-about-section-s2 section-padding">
            <div className="container">
                <div className="row ">
            
                    <div className="col-lg-6 col-md-12 col-12 pe-5">
                        <div >
                            <div className="wpo-section-title-about">
                                <h2>About Us</h2>
                            </div>
                            <p className="sub-text">Artificial Intelligence is a transformative force that's already tremendously benefited humanity.
                            The Amplify Institute is a nonprofit corporation grounded in research, to amplify stories of the advancements to 
                            everyday quality of life artificial intelligence has enabled over the past decades.</p>
                        
                            
                        </div>
                        
                        <div >
                            <div className="wpo-section-title-about">
                                <h2>The Amplify Institute's Mission and Values</h2>
                            </div>

                            <p className="sub-text"> The free-market has fueled 
                            America’s entrepreneurial spirit, propelling groundbreaking advancements. As we enter 
                            the era of Artificial Intelligence, it is more crucial than ever to maintain this spirit 
                            of innovation. The Amplify Institute is thus committed to creating a landscape where AI can 
                            flourish, free from partisan regulation.
                            </p>

                            <p className="sub-text">By promoting education, supporting innovation and developing talent, we can avoid policies 
                                that stifle innovation in the rapidly evolving AI landscape. In the process, we can rekindle 
                                the American entrepreneurial spirit, enhance the welfare of humanity and secure the promise 
                                of technology-driven liberty for decades to come.
                            </p>             
                        </div>

                        <div >
                            <div className="wpo-section-title-about">
                                <h2>Policy Foundations to Enable Innovation</h2>
                            </div>
                            <p className="sub-text">
                                Our nonpartisan policy approach is founded upon liberty, education and innovation, all of 
                            which are key to unleash AI’s full potential. At a time when technology is rapidly changing and may seem daunting, 
                            these ideals are critical to promoting a safe and prosperous future. 


                            </p>
                            <p className="sub-text">
                                Instead of hasty policy actions, we must prioritize educating ourselves, so we may learn the true potential 
                                AI holds to revolutionize lives. With meticulous research and talent development, unhindered by government 
                                regulation, we can inspire ethical AI growth and discovery that serves humanity. Only through this innovation 
                                and awareness, can we bring about a paradigm shift that encourages responsible regulation of AI technology 
                                and protects the liberty of Americans to continue pushing forward.
                            </p>             
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
    )
}

export default About;