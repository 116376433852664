import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import TeamSection from '../../components/FellowsSection/Fellows';
import Footer from '../../components/footer/Footer';



const TeamPage =() => {
    return(
        <Fragment>
            <Navbar hclass={'wpo-header-style-5'}/>
            <PageTitle pageTitle={'Fellowship'} pagesub={'Fellowship'} pageDescription={'Meet our AI and policy fellows and apply to our fellowship program to support your work.'}/> 
            <TeamSection tClass={'wpo-team-section-s3'}/>
            
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default TeamPage;
