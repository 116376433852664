import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import Homepage from '../HomePage/HomePage'
import AboutPage from '../AboutPage/AboutPage';
import TeamPage from '../Fellowship/FellowshipPage';
import BlogPage from '../BlogPage/BlogPage'
import BlogDetails from '../BlogDetails/BlogDetails'
import PapersPage from '../PapersPage/PapersPage'
import PaperDetails from '../PaperDetails/PaperDetails'
import ContactPage from '../ContactPage/ContactPage';
import ErrorPage from '../ErrorPage/ErrorPage';

const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="home" element={<Homepage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="fellowship" element={<TeamPage />} />
          <Route path='partner' element={<ContactPage />} />
          <Route path='stories' element={<BlogPage />} />
          <Route path='story/:slug' element={<BlogDetails />} />
          <Route path='papers' element={<PapersPage />} />
          <Route path='paper/:slug' element={<PaperDetails/>} />
          <Route path='404' element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
