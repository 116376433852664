import React from 'react';
import ContactForm from '../GrantApplication/GrantApplication'


const Contactpage = () => {

    return(
        <section className="wpo-contact-pg-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-10 offset-lg-1">
                        <div className="office-info">
                            <div className="row justify-content-center">
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-email"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Email Us</h2>
                                            <p>info@amplifyinstitute.org</p>
                                        </div>
                                    </div>
                                </div> 
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-phone-call"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Call Now</h2>
                                            <p>+1(202) 656-1717</p>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                        {/* <div className="wpo-contact-title">
                            <h2>Questions?</h2>
                            <p>Get in touch directly with our team to partner, work with us, or anything else.</p>
                        </div>
                        <div >
                            <ContactForm/>
                        </div>  */}
                    </div>                
                </div>
            </div> 
            
        </section>
     )
        
}

export default Contactpage;
