import React from 'react'
// import { Link } from 'react-router-dom'
import policy from '../../api/policyFellows'
import tech from '../../api/techFellows'
import Application from '../GrantApplication/GrantApplication'

// const ClickHandler = () => {
//     window.scrollTo(10, 0);
// }

const TeamSection = (props) => {
    return (
        <section className="wpo-team-section section-padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col col-md-6 col-12">
                        <div className="wpo-section-title">
                            <div className="section-titl-shape-1">
                                <svg width="687" height="473" viewBox="0 0 687 473" fill="none">
                                    <g opacity="0.44" filter="url(#filter0_f_1_1042)">
                                        <circle cx="343.5" cy="129.5" r="129.5" />
                                    </g>
                                    <defs>
                                        <filter id="filter0_f_1_1042" x="0" y="-214" width="687" height="687"
                                            filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix"
                                                result="shape" />
                                            <feGaussianBlur stdDeviation="107" result="effect1_foregroundBlur_1_104" />
                                        </filter>
                                    </defs>
                                </svg>
                            </div>
                            <div className="section-titl-shape-2">
                                <svg width="687" height="473" viewBox="0 0 687 473" fill="none">
                                    <g opacity="0.44" filter="url(#filter0_f_1_1052)">
                                        <circle cx="343.5" cy="129.5" r="129.5" />
                                    </g>
                                    <defs>
                                        <filter id="filter0_f_1_1052" x="0" y="-214" width="687" height="687"
                                            filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix"
                                                result="shape" />
                                            <feGaussianBlur stdDeviation="107" result="effect1_foregroundBlur_1_105" />
                                        </filter>
                                    </defs>
                                </svg>
                            </div>
                            <h2>OUR FELLOWS</h2>
                            <span>Leaders at the forefront of AI and Policy Research.</span>
                            {/* <h2>Policy Fellows</h2> */}
                        </div>
                    </div>
                </div>
                <div className="wpo-team-wrap justify-content-center">
                    <div className="row justify-content-center">
                        {policy.map((team, aitem) => (
                            <div className="col col-lg-3 col-md-6 col-12" key={aitem}>
                                <div className="wpo-team-item">
                                    <div className="wpo-team-img">
                                        <img src={team.tImg} alt="" />
                                        {/* <div className="wpo-team-text">
                                            <span>{team.title}</span>
                                            <h2>{team.name}</h2>
                                            <div className="button">
                                                <Link onClick={ClickHandler} to={`/team-single/${team.slug}`}><i className="ti-plus"></i></Link>
                                            </div>
                                        </div> */}
                                    </div>
                                    
                                </div>
                                <div className="d-flex flex-column justify-content-center align-items-center w-100">
                                    <h3>{team.name}</h3>
                                    <p className="fw-bold text-black">{team.title}</p>
                                </div>
                                
                            </div>
                        ))}
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col col-md-6 col-12">
                        <div className="wpo-section-title">
                            {/* <h2>Technology Fellows</h2> */}
                        </div>
                    </div>
                    <div className="wpo-team-wrap">
                    <div className="row justify-content-center">
                        {tech.map((team, aitem) => (
                            <div className="col col-lg-3 col-md-6 col-12" key={aitem}>
                                <div className="wpo-team-item">
                                    <div className="wpo-team-img">
                                        <img src={team.tImg} alt="" />
                                        {/* <div className="wpo-team-text">
                                            <span>{team.title}</span>
                                            <h2>{team.name}</h2>
                                            <div className="button">
                                                <Link onClick={ClickHandler} to={`/team-single/${team.slug}`}><i className="ti-plus"></i></Link>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="d-flex flex-column justify-content-center align-items-center w-100">
                                    <h3>{team.name}</h3>
                                    <p className="fw-bold text-black">{team.title}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <section className="wpo-contact-pg-section" style={{ marginTop: '50px', borderTop: '1px solid #ccc', paddingTop: '20px' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col col-lg-10 offset-lg-1">
                                <br></br>
                                <div className="wpo-contact-title">
                                    <h2>Apply for our Fellowship Program</h2>
                                </div>
                                <h3>Amplify Fellowship</h3>
                                <p className="sub-text">Apply to become an Amplify Fellow to work with the best researchers across policy and technology, get a dedicated team to support your work, and a platform to further the reach of your research.</p>
                                
                                <div className="wpo-contact-title">
                                    <h3>Application Form</h3>
                                </div>
                                <Application /> 
                            </div>
                        </div>
                    </div>
            </section>
        </div>
        </section>
        
    )
}

export default TeamSection;