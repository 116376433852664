import React, { useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';

const NewsletterSignup = () => {
    const [email, setEmail] = useState('');
    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));
    const [message, setMessage] = useState('');

    const changeHandler = e => {
        setEmail(e.target.value);
        // validator.showMessages();
    };

    const submitHandler = async (e) => {
        e.preventDefault();
    
        // Google Form POST URL
        const formUrl = "https://docs.google.com/forms/d/e/1FAIpQLSdYuQj9wwU8PHbHTiMZCIU_VU3GrZaq7YOLsPJYAVjzHAp8hg/formResponse";
        const emailFieldName = "entry.623282512";
    
        const formData = new URLSearchParams();
        formData.append(emailFieldName, email);
    
        // Send the email to Google Forms
        try {
            await fetch(formUrl, {
                method: "POST",
                body: formData,
                mode: "no-cors",
            });
            setMessage("You've joined the Amplify newsletter!");
        } catch (error) {
            setMessage("Failed to subscribe. Please try again.");
        }
    
        setEmail('');
    };

    // Inline styles
    const styles = {
        formContainer: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%', // Adjust as per your requirements
        },
        inputField: {
            flex: 1,
            padding: '8px 12px',
            border: '1px solid #ccc',
            borderRadius: '4px 0 0 4px',
        },
        submitBtn: {
            flex: 0.25,
            padding: '8px 12px',
            backgroundColor: '#007bff',
            border: '1px solid #007bff',
            borderRadius: '0 4px 4px 0',
            color: '#fff',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease',
        },
        submitBtnHover: {
            backgroundColor: '#0056b3',
        },
        successMessage: {
            color: 'green',
            marginTop: '10px',
        }
    };

    return (
        <form onSubmit={submitHandler} className="newsletter-validation">
            <div style={styles.formContainer}>
                <input
                    value={email}
                    type="email"
                    name="email"
                    onBlur={changeHandler}
                    onChange={changeHandler}
                    placeholder="Your Email"
                    style={styles.inputField}
                />
                <button
                    type="submit"
                    className="theme-btn"
                    style={styles.submitBtn}
                >
                    Join
                </button>
            </div>
            {/* {validator.message('email', email, 'required|email')} */}
            {message && <p style={styles.successMessage}>{message}</p>}
        </form>
    );
};

export default NewsletterSignup;
