import React, {Fragment} from 'react';
import PageTitle from '../../components/pagetitle/PageTitle.js'
import PaperList from '../../components/PaperComponents/PaperList/PaperList.js'
import Navbar from '../../components/Navbar/Navbar.js';
import Footer from '../../components/footer/Footer.js';
import Scrollbar from '../../components/scrollbar/scrollbar.js'

const BlogPage =() => {
    return(
        <Fragment>
            <Navbar hclass={'wpo-header-style-5'}/>
            <PageTitle pageTitle={'Whitepapers'} pagesub={'Papers'} pageDescription={'Facts and Figures about What AI is (and Isn\'t) Doing.'}/> 
            <PaperList/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default BlogPage;

