import React from 'react'

import abImg from '../../images/about.jpg'


const About = (props) => {
    return (
        <section className="wpo-about-section-s2 section-padding">
            <div className="container">
                <div className="row ">
            
                    <div className="col-lg-6 col-md-12 col-12 pe-5">
                        <div >
                            <div className="wpo-section-title-about">
                                <h2>About Us</h2>
                            </div>
                            <p className="sub-text">Artificial Intelligence is a transformative force that's already tremendously benefited humanity. The Amplify Institute is a nonprofit corporation grounded in research, to amplify stories of the advancements to everyday quality of life artificial intelligence has enabled.</p>
                        
                            
                        </div>
                        
                        <div >
                            <div className="wpo-section-title-about">
                                <h2>Our Mission and Values</h2>
                            </div>

                            <p className="sub-text">The free-market has fueled America’s entrepreneurial spirit, propelling groundbreaking advancements. Over the past decades, technological advancements have revolutionized our way of life in the United States. AI is a large part of this, with the technology being responsible for improvements in everything from healthcare, education, productivity, entertainment, and business. 
                            </p>

                            <p className="sub-text">
                            As AI has been brought more into the spotlight due to recent advancements in technology, there’s been a lot of fear and confusion around AI from the general public, politicians, and world leaders. We believe that AI will continue to create tremendous good for humanity, and as it becomes a larger part of our lives, we are committed to sharing stories of everyday societal progress caused by AI. This will help reduce fear and confusion around the technology, creating a landscape where AI can flourish, free from partisan regulation and constraints.
                            </p>

                            <p className="sub-text">By promoting education, supporting innovation and developing talent, we can avoid policies that stifle innovation in the rapidly evolving AI landscape. In the process, we can rekindle the American entrepreneurial spirit, enhance the welfare of humanity and secure the promise of technology-driven liberty for the decades to come.
                            </p>             
                        </div>
                        <div >
                            <div className="wpo-section-title-about">
                                <h2>What We Do</h2>
                            </div>
                            <p className="sub-text">
                                <p></p>
                                <h4>Stories</h4>
                                We are committed to sharing the stories of everyday societal impacts and beneficial use cases that AI has already enabled in our lives to demystify and reduce the fear and confusion around AI. 
                                <p></p>
                                <h4>Education</h4>
                                We are committed to educating the public on fundamentals of AI, including the principles of the technology, the history of its development and surrounding regulation, and its primary use cases and ways in which it has changed various industries for the better.
                                <p></p>
                                <h4>Research</h4>
                                We are committed to supporting capabilities research in both policy and technology by supporting researchers with competitive fellowship programs.
                                <p></p>
                            </p>
                        
                            
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12 ps-5">
                        <div className="wpo-about-wrap">
                            <div className="wpo-about-img">
                                <img src={abImg} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;