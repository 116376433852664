// images
// import manufacturingSmall from "../images/blog/manufacturingSmall.jpg"; 
// import farmingSmall from "../images/blog/farmingSmall.jpg";
// import historySmall from "../images/blog/historySmall.jpg";
// import introSmall from "../images/blog/introSmall.jpg";

// import farmingSingle from "../images/blog/blog-single/farming.jpg"; // this is the main picture of the blog 
// import manufacturingSingle from "../images/blog/blog-single/manufacturing.jpg";
// import historySingle from "../images/blog/blog-single/history.jpg";
// import introSingle from "../images/blog/blog-single/intro.jpg";

import realtorThumbnail from "../images/blog/blog-video-thumbnails/realtorThumbnail.jpg"
import realtorSmall from "../images/blog/blog-video-thumbnails/realtorThumbnailSmall.jpg"

import musicianThumbnail from "../images/blog/blog-video-thumbnails/musicianThumbnail.jpg"
import musicianSmall from "../images/blog/blog-video-thumbnails/musicianThumbnailSmall.jpg"

// import designerThumbnail from "../images/blog/blog-video-thumbnails/designerThumbnail.jpg"
// import designerSmall from "../images/blog/blog-video-thumbnails/designerThumbnailSmall.jpg"



const blogs = [
    { // done
        id: '2',
        title: 'Amplify Talks: AI in Real Estate',
        slug:'ai-in-real-estate',
        screens: realtorSmall,
        description: 'A conversation with Eric Bradley about AI in real estate',
        author: 'The Amplify Institute',
        create_at: 'May 2, 2024',
        blogSingleImg:realtorThumbnail, 
        comment:'',
        day:'2',
        month:'May',
        blClass:'format-video',
        animation:'1200',
        preview: 'Hear from a real estate agent on how AI is being used in his business and where he sees it going as it continues to transform the future.',
        content: 'Hear from a real estate agent on how AI is being used in his business and where he sees it going as it continues to transform the future.',
        videoUrl: '943065860' 
    },
    {
        id: '1',
        title: 'Amplify Talks: Musical AI',
        slug:'ai-in-music',
        screens: musicianSmall,
        description: 'Hear from Asher Jeffe on how AI increases efficiency in music production',
        author: 'The Amplify Institute',
        create_at: 'April 13, 2024',
        blogSingleImg:musicianThumbnail, 
        comment:'',
        day:'13',
        month:'April',
        blClass:'format-video',
        animation:'1200',
        preview: "AI is already transforming the most unlikely of industries. Learn how this technology can be used to enhance an artist's life in the music industry.",
        content: "AI is already transforming the most unlikely of industries. Learn how this technology can be used to enhance an artist's life in the music industry.",
        videoUrl: '943069717' 
    },
    // {
    //     id: '3',
    //     title: 'Amplify Talks: Designing with AI',
    //     slug:'designing-with-ai',
    //     screens: designerSmall,
    //     description: "Taking a look at AI's ability to enhance a designer's workflow and optimize efficiency",
    //     author: 'The Amplify Institute',
    //     create_at: 'April 10, 2024',
    //     blogSingleImg:designerThumbnail, 
    //     comment:'',
    //     day:'28',
    //     month:'April',
    //     blClass:'format-video',
    //     animation:'1200',
    //     preview: "Discover how AI is applied in a variety of industries. First up, optimizing interior and video game design with Asada!",
    //     content: "Discover how AI is applied in a variety of industries. First up, optimizing interior and video game design with Asada!",
    //     videoUrl: 'T6eK-2OQtew' // TODO
    // },
    
];
export default blogs;