import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import Logo from '../../images/logo/logo-s3.svg'
import NewsletterSignup from '../NewsletterSignup/NewsletterSignup';

const ClickHandler = () => {
    window.scrollTo(10, 0);
}
const SubmitHandler = (e) => {
    e.preventDefault()
}

const Footer = (props) => {

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <footer className="wpo-site-footer">
            <div className="shape-1">
                <svg width="795" height="12" viewBox="0 0 795 12" fill="none">
                    <rect width="795" height="12" fill="url(#paint0_linear_1_223)" />
                    <defs>
                        <linearGradient id="paint0_linear_1_223" x1="795" y1="5.99883" x2="9.55538e-10" y2="5.99883"
                            gradientUnits="userSpaceOnUse">
                            <stop offset="0" stopColor="#274DCF" />
                            <stop offset="1" stopColor="white" stopOpacity="0" />
                        </linearGradient>
                    </defs>
                </svg>
            </div>
            <div className="shape-2">
                <svg width="795" height="12" viewBox="0 0 795 12" fill="none">
                    <rect width="795" height="12" fill="url(#paint0_linear_1_224)" />
                    <defs>
                        <linearGradient id="paint0_linear_1_224" x1="2.31964e-06" y1="5.99883" x2="795" y2="5.99898"
                            gradientUnits="userSpaceOnUse">
                            <stop offset="0" stopColor="#DE2045"/>
                            <stop offset="1" stopColor="white" stopOpacity="0" />
                        </linearGradient>
                    </defs>
                </svg>
            </div>
            <div className="wpo-upper-footer">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        {/* Logo Column - always visible and takes minimal necessary space */}
                        {!isMobile && <div className="col-md-auto">
                            <div className="widget about-widget">
                                <div className="logo widget-title pt-3" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Link to="/">
                                        <img src={Logo} alt="Amplify" style={{ verticalAlign: 'middle' }}/>
                                    </Link>
                                </div>
                            </div>
                        </div>}
                        
                        {/* Flexible Column Setup for Links */}
                        {/* Ensuring each link column is responsive and adjusts without wrapping prematurely */}
                        <div className="col">
                            <div className="widget link-widget">
                                <h3><Link onClick={ClickHandler} to="/about" className="text-white ps-3">About</Link></h3>
                            </div>
                        </div>
                        <div className="col">
                            <div className="widget link-widget">
                                <h3><Link onClick={ClickHandler} to="/stories" className="text-white">Stories</Link></h3>
                            </div>
                        </div>
                        <div className="col">
                            <div className="widget link-widget">
                                <h3><Link onClick={ClickHandler} to="/papers" className="text-white">Papers</Link></h3>
                            </div>
                        </div>
                        <div className="col">
                            <div className="widget link-widget">
                                <h3><Link onClick={ClickHandler} to="/fellowship" className="text-white">Fellowship</Link></h3>
                            </div>
                        </div>
                        <div className="col">
                            <div className="widget link-widget">
                                <h3><Link onClick={ClickHandler} to="/partner" className="text-white ps-2">Partner</Link></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="align-items-center d-flex justify-content-center">
                <h4 className="text-white ps-3">Join the Newsletter</h4>
            </div>
            <div className="align-items-center d-flex justify-content-center">
                <NewsletterSignup />
            </div>
            <br></br>
            <div className="wpo-lower-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-12">
                            <p className="copyright"> Copyright &copy; 2024 <Link onClick={ClickHandler} to="/home">The Amplify Institute</Link>. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer >
    )
}

export default Footer;