import name1 from '../images/fellows/tech/name1.jpg'
import name2 from '../images/fellows/tech/name2.jpg'
import name3 from '../images/fellows/tech/name3.jpg'
import name4 from '../images/fellows/tech/name4.jpg'


const Teams = [
    {
        Id: '1',
        tImg:name1,
        slug:'anaiy',
        name:'Anaiy Somalwar',
        title:'Researcher at BAIR | VC at Foundation',
    },
    {
        Id: '2',
        tImg:name2,
        slug:'Emily-Park',
        name:'Emily Park',
        title:'ML Researcher at DOD and DARPA',
    }
]

export default Teams;