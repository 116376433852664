
import React, { Fragment, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';


import abImg from '../../images/about-s2.jpg'
// import abImg from '../../images/about-s2.jpg'
import icon1 from '../../images/about-icon-1.png'
import icon2 from '../../images/about-icon-2.png'

const About = (props) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    
    return (
        <section className="wpo-about-section-s2 section-padding">
            <div className="container">
                <div className="row align-items-center">
                    {<div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-wrap">
                            <div className="wpo-about-img">
                                <img src={abImg} alt=""/>
                            </div>
                        </div>
                    </div>}
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-text">
                            <div className="wpo-section-title">
                                <span>About Amplify</span>
                                <h2>AI Has Shaped the World – We're Amplifying It</h2>
                            </div>
                            <p className="sub-text">AI has enabled advancements crucial to solving some of humanity’s 
                            most pressing problems: discovering drugs curing previously incurable diseases, improving access to quality healthcare, personalizing and improving education, improving safety in our cars, giving us more accurate weather forecasts to be more prepared for disasters, and much more. 
                            We are committed to sharing the stories of everyday societal progress because of AI, and to support education and research that enables AI to reach its full potential.</p>

                            {!isMobile && <div className="row">
                                <div className="col-md-6 col-12">
                                    <div className="about-content">
                                        <div className="images">
                                            <img src={icon1} alt=""/>
                                        </div>
                                        <div className="text">
                                            <h3>EDUCATION</h3>
                                            <p>An education-first approach to share AI's impacts and usages.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="about-content">
                                        <div className="images">
                                            <img src={icon2} alt=""/>
                                        </div>
                                        <div className="text">
                                            <h3>RESEARCH</h3>
                                            <p>Supporting capabilities research to demystify what AI is (and is not).</p>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;