import React, { Fragment, useState, useEffect } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import About from '../../components/AboutContent/aboutContent';
import Mobile from '../../components/AboutContent/aboutContentMobile'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';

const AboutPage = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Fragment>
            <Navbar hclass={'wpo-header-style-5'}/>
            <PageTitle pageTitle={'About'} pagesub={'About'} pageDescription={'Stories of the mundane everyday societal progress enabled by AI.'}/>
            {isMobile ? <Mobile /> : <About />}
            <Footer />
            <Scrollbar />
        </Fragment>
    );
};

export default AboutPage;
