import React from "react";
import { Link } from 'react-router-dom'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import fImg1 from '../../images/election-donation/1.svg'
import fImg2 from '../../images/election-donation/2.svg'
import fImg3 from '../../images/election-donation/3.svg'

const settings = {
    dots: false,
    arrows: true,
    speed: 1000,
    centerMode: false,
    centerPadding:0,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
        {
            breakpoint: 1400,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
            }
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                centerMode: false,
            }
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                centerMode: false,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false,
            }
        }
    ]
};

const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const Features = (props) => {
    return (
        <section className={`wpo-election-donation-section-s2 section-padding ${props.fClass}`}>
            <div className="container">
                <div className="row-grid wpo-service-slider-s2">
                    <Slider {...settings}>
                        <div className="grid">
                            <div className="wpo-service-item">
                                <div className="wpo-service-text">
                                    <div className="service-icon">
                                        <img src={fImg1} alt="" />
                                    </div>
                                    <h2>OUR BELIEFS</h2>
                                    <p>We believe in sharing the stories of the real benefits of AI today, rather than speculative unrealistic theoretical harm in the future by AI.</p>
                                    <Link onClick={ClickHandler} to="/about">Details</Link>
                                </div>
                            </div>
                        </div>
                        
                        <div className="grid">
                            <div className="wpo-service-item">
                                <div className="wpo-service-text">
                                    <div className="service-icon">
                                        <img src={fImg3} alt="" />
                                    </div>
                                    <h2>BECOME A FELLOW</h2>
                                    <p>Join us as an Amplify Fellow to help accelerate the development of AI for the benefit of humanity, on either the technology or policy side.</p>
                                    <Link onClick={ClickHandler} to="/fellowship">Details</Link>
                                </div>
                            </div>
                        </div>
                        <div className="grid">
                            <div className="wpo-service-item">
                                <div className="wpo-service-text">
                                    <div className="service-icon">
                                        <img src={fImg2} alt="" />
                                    </div>
                                    <h2>PARTNER</h2>
                                    <p>Contact us if you're interested in working with us, joining the team, partnering with our organization, or making a donation to support the cause.</p>
                                    <Link onClick={ClickHandler} to="/partner">Details</Link>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
        </section>
    )
}

export default Features;