import name1 from '../images/fellows/policy/name1.jpg'
import name2 from '../images/fellows/policy/name2.jpg'
import name3 from '../images/fellows/policy/name3.jpg'
import name4 from '../images/fellows/policy/name4.jpg'


const Teams = [
    // {
    //     Id: '1',
    //     tImg:name1,
    //     slug:'Peter-Coe',
    //     name:'Peter Coe',
    //     title:'AI Policy Researcher | Berkeley Law',
    // },
    {
        Id: '2',
        tImg:name2,
        slug:'Miklos-Sunario',
        name:'Miklos Sunario',
        title:'2x UN Keynote Speaker on AI',
    }
]

export default Teams;